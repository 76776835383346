body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
}

nav {
  background-color: #343a40;
  color: white;
  padding: 1rem;
  border-radius: 10px;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav ul li {
  margin: 0.5rem 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #f7c873;
}

h2 {
  color: #495057;
  text-align: center;
  margin-top: 1rem;
  font-weight: bold;
}

button {
  background-color: #f7c873;
  color: #fff;
  border: none;
  padding: 0.7rem 1.2rem;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #eea636;
  transform: translateY(-2px);
}

input[type="text"],
input[type="number"],
select {
  width: 90%;
  padding: 0.7rem;
  margin: 0.5rem auto;
  display: block;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  font-size: 1rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

table th,
table td {
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
  text-align: left;
  font-size: 0.9rem;
}

table th {
  background-color: #f7c873;
  color: white;
}

.container {
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.product-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.product-card {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.product-image-small {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 16px;
}

.product-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.product-checkbox {
  margin-right: 16px;
}

.product-checkbox input[type="checkbox"] {
  cursor: pointer;
}

.product-view .product-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.product-view .product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 600px) {
  .product-view .product-details {
    flex-direction: row;
  }

  .product-view .product-info {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    margin-left: 20px;
  }
}
.product-info p {
  margin: 5px 0; /* 調整這裡的 margin 可以減小行距 */
  line-height: 1.2; /* 可以適當調整行高來達到你想要的效果 */
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 10px;
}

.modal p {
  margin-bottom: 20px;
}

.modal button {
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 5px;
}

.flag {
  cursor: pointer;
  padding: 1rem 2rem;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;
}

.flag:hover {
  background-color: #f7c873;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  margin-bottom: 20px;
}

.login-container input {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 200px;
}

.login-container button {
  padding: 10px 15px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #0056b3;
}

.product-image-large {
  width: 150px; /* 調整寬度 */
  height: auto; /* 保持自動高度以維持圖片比例 */
  object-fit: contain; /* 確保圖片在容器內完整顯示 */
  border-radius: 10px; /* 圓角設計 */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 添加陰影效果 */
  margin-bottom: 20px; /* 增加底部空間 */
}
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
  text-align: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

.country-button {
  padding: 1rem 2rem;
  margin: 1rem 0;
  font-size: 1.5rem;
  border: none;
  border-radius: 8px;
  background-color: #f7c873;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.country-button:hover {
  background-color: #f7c873;
}

.country-button span {
  margin-right: 0.5rem;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f8fc; /* 背景顏色改為較淡的灰色 */
  padding: 20px;
  border-radius: 10px; /* 添加圓角 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加陰影 */
}

.login-container h2 {
  margin-bottom: 20px;
  font-size: 1.8rem; /* 調整標題字體大小 */
  color: #333; /* 字體顏色 */
}

.login-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px; /* 最大寬度 */
}

.login-container input {
  padding: 12px; /* 增加內邊距 */
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.login-container input:focus {
  border-color: #007bff; /* 焦點時的邊框顏色 */
  outline: none;
}

.login-container button {
  padding: 12px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #0056b3; /* 懸停時的背景顏色 */
}

.login-container button:disabled {
  background-color: #ccc; /* 禁用狀態的背景顏色 */
  cursor: not-allowed;
}
/* 橘色的切換國家按鈕樣式 */
.switch-country-button {
  background-color: #f7a374; /* 橘色背景 */
  color: white; /* 白色文字 */
  border: none;
  padding: 0.5rem 1.5rem; /* 增加一些內邊距使按鈕更大 */
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 0.25rem; /* 圓角 */
  font-size: 1rem;
  display: block;
  margin: 1rem auto;
  transition: background-color 0.3s ease; /* 添加平滑過渡效果 */
}

/* 切換國家按鈕 - 橙色 */
.switch-country-button {
  background-color: #f7a374; /* 橙色 */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 1rem;
  display: block;
  margin: 1rem auto;
  width: 90%;
}

.switch-country-button:hover {
  background-color: #FF7000; /* 更深的橙色，當鼠標懸停時 */
}

/* 展開選單按鈕 - 原來的黃色 */
.menu-button {
  background-color: #7c9b86; /* 黄色 */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 1rem;
  display: block;
  margin: 1rem auto;
  width: 90%;
}

.menu-button:hover {
  background-color: #7c9b86; /* 更深的黄色，當鼠標懸停時 */
}
/* 控制编辑页面中商品图片的大小 */
.product-image-preview {
  max-width: 200px; /* 设置图片的最大宽度 */
  max-height: 200px; /* 设置图片的最大高度 */
  object-fit: cover; /* 保持图片的纵横比，避免拉伸变形 */
  margin-top: 10px; /* 图片与上方元素的间距 */
}


